<template>
  <!-- 验证邮箱 -->
  <div id="house-login" class="layui-fulid findpassword">
    <div class="layui-form">
      <div v-if="verifying" style="color: #000">正在验证中...</div>
      <div v-if="verifyOk && !verifying" class>
        <img class="icon" src="@/assets/img/sentok-icon.png" />
        <h4>邮箱验证成功</h4>
      </div>
      <div v-if="!verifyOk && !verifying">
        <img
          class="icon"
          src="@/assets/center/cross.png"
          style="margin-bottom: 30px; margin-top: 0px"
        />
        <h4>{{ errMsg }}</h4>
      </div>
      <button
        v-if="!verifying && !verifyOk"
        class="layui-btn login"
        lay-filter="user-login"
        lay-submit
        @click="goMainPage"
      >
        {{ btntext }}
      </button>
    </div>
  </div>
</template>
<script>
import { userVerify } from "../../service";

export default {
  name: "userVerifycation",
  data() {
    return {
      email: "",
      verifyOk: false,
      btntext: "返回首页",
      verifying: true,
      errMsg: "",
    };
  },
  mounted() {
    this.userVerifycation();
  },
  methods: {
    userVerifycation() {
      userVerify(
        this.$route.query.key,
        this.$route.query.invitationEmailCode
      ).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.verifyOk = true;
          this.verifying = false;
          window.localStorage.setItem("CSON_PAGE_TOKEN", res.result.token);

          // setTimeout(() => {
          //   window.location.href =
          //     "#/?participateActive=" + res.result.participateActive;
          // }, 1500);
        } else {
          this.verifyOk = false;
          this.verifying = false;
          this.errMsg = res.message;
        }
      });
    },
    goMainPage() {
      window.location.href = "/";
    },
  },
};
</script>
<!-- Event snippet for 注册账号 conversion page -->
<script>
gtag("event", "conversion", { send_to: "AW-316705807/8MRqCPrqxO4CEI-YgpcB" });
</script>